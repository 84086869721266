import React from 'react';
import classnames from 'classnames';

import { existy, isEmpty } from '@dop/shared/helpers/functional';
import { IconPijlRechts } from '@dop/ui-icons/svg/pijlRechts';
import { PromoImage } from '@dop/shared/components/image/promoImage';
import { Markup } from '@dop/shared/components/markup/Markup';
import { Link } from 'moduleAlias/project/src/components/link/link';
import { getBlockTypeColorName } from 'moduleAlias/project/configuration/getBlockTypeColorName';

const getBlockContainerMoreClassName = (type) => {
	const colorName = getBlockTypeColorName(type);

	return classnames({
		'blockContainer-more': true,
		[`blockContainer-more--${colorName}`]: existy(colorName),
	});
};

const getPromoBlockClassName = (type) => {
	const colorName = getBlockTypeColorName(type);

	return classnames({
		promoBlock: true,
		[`promoBlock--${colorName}`]: existy(colorName),
	});
};

export const makeMoreLink = (item) => {
	if (!item.getIn(['moreLink', 'text'])) {
		return null;
	}

	return (
		<div className={getBlockContainerMoreClassName(item.get('type'))}>
			<Link
				to={item.getIn(['moreLink', 'href'])}
				linkType="moreLink"
				className={'interfaceLink promoBlock-more'}
			>
				<IconPijlRechts hidden={true} className="goIcon interfaceLink-icon" />
				<span>
					<span className="underliner">{item.getIn(['moreLink', 'text'])}</span>
				</span>
			</Link>
		</div>
	);
};

export const PromoBlock = ({ item }) => {
	if (isEmpty(item)) {
		return null;
	}
	const linkHref = item.get(
		'href',
		item.getIn(['moreLink', 'href'], item.getIn(['linkList', 0, 'href'])),
	);

	return (
		<div className="column column--third blockContainer">
			<Link
				to={linkHref}
				className={getPromoBlockClassName(item.get('type'))}
				linkType={`${item.get('type')}PromoBlock`}
				checkExternal={false}
			>
				<div className="promoBlock-top">
					{item.getIn(['image', 'src']) && (
						<figure className="promoBlock-figure">
							<PromoImage
								className="promoBlock-image"
								alt={item.getIn(['image', 'alt'])}
								src={item.getIn(['image', 'src'])}
							/>
						</figure>
					)}
					{item.get('label') ? (
						<div className="promoBlock-label">{item.get('label')}</div>
					) : null}
				</div>
				<div className="promoBlock-content">
					{item.get('title') || item.get('metaInfo') ? (
						<h2 className="promoBlock-title h4">
							{item.get('title')}
							{item.get('metaInfo') ? (
								<span className="metaInfo">{item.get('metaInfo')}</span>
							) : null}
						</h2>
					) : null}
					{item.getIn(['ankeiler', 'html']) ? (
						<Markup html={item.getIn(['ankeiler', 'html'])} />
					) : (
						<p>{item.get('ankeiler')}</p>
					)}
				</div>
			</Link>
			{makeMoreLink(item)}
		</div>
	);
};

export default PromoBlock;
